import React, { useEffect, useState } from "react";

import Amplify, { API, Auth } from "aws-amplify";
import awsconfig from "../aws-config-manual";
import { Link } from "react-router-dom";
import Button from "@material-ui/core/Button";
import Paper from "@material-ui/core/Paper";
import CircularProgress from "@material-ui/core/CircularProgress";
import withStyles from "@material-ui/core/styles/withStyles";
import { Formik } from "formik";
import Typography from "@material-ui/core/Typography";
import { ErrorDialog } from "./ErrorDialog";
import * as Yup from "yup";
import PersonForm from "./PersonForm";
import { useTranslation, getI18n } from "react-i18next";
import { DateUtils } from "@aws-amplify/core";
// import awsconfig from '../aws-exports';
Amplify.configure(awsconfig);

// Auth.configure({
//   authenticationFlowType: 'USER_PASSWORD_AUTH'
// });

const products = [
  {},
  {
    id: 1,
    title: "Digitale Vriend",
    price: 50.00,
    buttonText: "Word Digitale Vriend",
  },
  {
    id: 2,
    title: "Klassieke Vriend",
    price: 50.00,
    buttonText: "Word Klassieke Vriend",
    abroadPrice: 24.65
  },
  {
    id: 3,
    title: "WieWasWie Jaar",
    price: 23.55,
    buttonText: "Jaartoegang kopen",
  },
  {
    id: 4,
    title: "Klassieke Vriend + WieWasWie",
    price: 61.8,
    buttonText: "Klassieke vriend + WWW",
    abroadPrice: 24.65,
  },
  {
    id: 5,
    title: "Dagtoegang CBG",
    price: 7.45,
    buttonText: "Dagtoegang kopen",
  },
  {
    id: 6,
    title: "WieWasWie Maand",
    price: 6.85,
    buttonText: "Maandtoegang kopen",
  },
  {
    id: 7,
    title: "Uitbreiding met WieWasWie Jaar",
    price: 11.80,
    buttonText: "Jaartoegang kopen",
  },
  {
    id: 8,
    title: "Klassieke Vriend + WieWasWie",
    price: 61.8,
    buttonText: "Word Klassieke Vriend",
    discount: 11.80,
    abroadPrice: 24.65,
  },
  {
    id: 9,
    title: "Digitale CombiVriend",
    price: 36,
    buttonText: "Word Digitale CombiVriend",
  },
];

const styles = (theme) => ({
  layout: {
    width: "auto",
    margin: theme.spacing(2),
    [theme.breakpoints.up(800 + theme.spacing(2 * 2))]: {
      width: 800,
      marginLeft: "auto",
      marginRight: "auto",
    },
  },
  paper: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
    padding: theme.spacing(2),
  },
  buttons: {
    display: "flex",
    justifyContent: "flex-end",
  },
  button: {
    marginTop: theme.spacing(3),
    marginLeft: theme.spacing(1),
  },
});

function Order(props) {
  const { classes, match } = props;
  const { t } = useTranslation();

  const [productId, setProductId] = useState(match.params.productId);
  const [initialValues, setInitialValues] = useState({});
  const [values, setValues] = useState({});
  const [error, setError] = useState({});
  const [open, setOpen] = useState(false);
  const [isLoading, setLoading] = useState(true);
  const [isSubmitting, setSubmitting] = useState(false);
  const [redirect, setRedirect] = useState();

  const { title, price, abroadPrice, discount } = products[productId];

  useEffect(() => {
    async function getPersonInfo(productId) {
      if (true) {
        const language = getI18n().language;
        const apiName = "oAuth";
        const path = "/shop/personInfo";
        const queryStringParameters = { productId };
        try {
          const cognitoUser = await Auth.currentAuthenticatedUser();
          const session = cognitoUser.getSignInUserSession();
          DateUtils.setClockOffset(session.clockDrift * 1000 * -1);
          const res = await API.get(apiName, path, { queryStringParameters });
          // console.log(res);
          if (res.message === "incompatibleProduct") {
            setLoading(false);
            setError(res);
          } else {
            setProductId(res.productId);
            // if(res.country === null) {
            //   res.country = '';
            // }
            for (const key in res) {
              if (res[key] === null) {
                res[key] = "";
              }
            }
            setInitialValues({ ...res, language });
            setLoading(false);
          }
        } catch (err) {
          console.error(err.message);
          console.error(err);
          setInitialValues({});
          setLoading(false);
          setError(err);
        }
      }
    }
    getPersonInfo(productId);
  }, [productId]);

  useEffect(() => {
    if (isSubmitting) {
      async function submitForm() {
        // console.log("submitted:");
        // console.log(values);
        const apiName = "oAuth";
        const path = `/shop/order/${productId}`;
        let myInit = {
          body: values,
        };
        try {
          const cognitoUser = await Auth.currentAuthenticatedUser();
          const session = cognitoUser.getSignInUserSession();
          DateUtils.setClockOffset(session.clockDrift * 1000 * -1);
          const res = await API.post(apiName, path, myInit);
          // console.log(res);
          if (res.mollieId && res.checkoutUri) {
            window.location.assign(res.checkoutUri);
          } else {
            setRedirect(`/shop/pay/${res.orderId}`);
          }
        } catch (err) {
          console.error(err);
          setOpen(err.message);
          setError(err);
        }
      }
      submitForm();
    }
  }, [isSubmitting, values, productId]);

  return (
    <React.Fragment>
      <main className={classes.layout}>
        <Paper className={classes.paper}>
          <Typography component="h2" align="center">
            {title}
          </Typography>
          <Typography variant="h6" component="h3" align="center">
            {price.toLocaleString("nl", { style: "currency", currency: "EUR" })}
          </Typography>
          {abroadPrice ? (
            <Typography variant="body2" align="center">
              + {abroadPrice.toLocaleString("nl", { style: "currency", currency: "EUR" })} buitenlandtoeslag bij een adres in het buitenland
            </Typography>
          ) : null}
          {discount ? (
            <Typography variant="body2" align="center">
              {discount.toLocaleString("nl", { style: "currency", currency: "EUR" })} korting in het eerste jaar
            </Typography>
          ) : null}
        </Paper>
        {error.message ? (
          <Paper className={classes.paper}>
            <Typography variant="h6" component="h3" align="center">
              {t(error.message)}
            </Typography>
            <Button variant="contained" color="primary" shape="chubby" href={t("signup_info_link")}>
              {t("Bestellen")}
            </Button>
            <Button variant="contained" color="primary" shape="chubby" href={process.env.REACT_APP_OUTSITE_URI}>
              {t("Mijn profiel")}
            </Button>
            <Button variant="contained" color="primary" shape="chubby" href={process.env.REACT_APP_CBG_URI}>
              CBG.nl
            </Button>
          </Paper>
        ) : (
          <Paper className={classes.paper}>
            {redirect ? (
              <Button variant="contained" color="primary" shape="chubby" component={Link} to={redirect}>
                Markeer als betaald
              </Button>
            ) : isLoading || isSubmitting ? (
              <CircularProgress className={classes.progress} />
            ) : (
              <Formik
                onSubmit={(values) => {
                  setValues(values);
                  setSubmitting(true);
                }}
                initialValues={initialValues}
                validationSchema={Yup.object({
                  firstName: Yup.string().required(t("First name is required")),
                  initials: Yup.string().max(15).required(t("Initials are required")),
                  lastName: Yup.string().required(t("Last name is required")),
                  country: Yup.string()
                    .required(t("Country is required"))
                    .test("country", t("Country is required"), function test(value) {
                      return value && value !== "" && value !== "--";
                    }),
                  houseNumber: Yup.string()
                    .matches(/^[0-9]+$/, t("Only numbers"))
                    .required(t("Housenumber is required")),
                  zipCode: Yup.string()
                    .required(t("Zipcode is required"))
                    .test("zipCode", t("Vereist formaat voor Nederland"), function test(value) {
                      return value && this.parent.country && this.parent.country === "NL" ? value.match(/^[0-9]{4} ?[A-Za-z]{2}$/) : true;
                    }),
                  street: Yup.string().required(t("Street is required")),
                  membership: Yup.string().max(15),
                })}
              >
                {(props) => PersonForm({ ...props, formVariant: "order" })}
              </Formik>
            )}
          </Paper>
        )}
        <ErrorDialog open={open} error={error} onClose={() => setOpen(false)} />
      </main>
    </React.Fragment>
  );
}

export default withStyles(styles)(Order);
